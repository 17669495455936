<template>
  <v-navigation-drawer
    v-model="isOpen"
    @input="$emit('update:isOpen', isOpen)"
    fixed
    temporary
    right
    hide-overlay
    class="drawerStyle pa-5"
    width="80%"
  >
    <div class="text-right mb-5">
      <v-btn
        fab
        x-small
        color="#a1b8d9"
        depressed
        @click="
          isOpen = false
          $emit('update:isOpen', false)
        "
      >
        <v-icon color="white">mdi-close</v-icon>
      </v-btn>
    </div>
    <div class="accent--text text-h6 font-weight-bold mb-4">設定內容</div>
    <v-row class="mx-0 font-weight-bold ems-bluegrey--text">
      <v-col cols="2">方案</v-col>
      <v-col cols="1">卸載量</v-col>
      <v-col cols="3">內容</v-col>
      <v-col cols="6">執行對象</v-col>
    </v-row>
    <v-row
      class="accent--text mx-0 font-weight-bold"
      style="border: 1px solid #082e62; border-radius: 10px"
    >
      <v-col cols="2">卸載約定型</v-col>
      <v-col cols="1">70 kW</v-col>
      <v-col cols="3">方案１：調整冷氣溫度 將溫度調整至28度</v-col>
      <v-col cols="6">全校一年級, 全校二年級, 全校專科教室</v-col>
    </v-row>
    <div class="mt-8">
      <v-row class="mx-0">
        <v-col cols="12" md="10" class="pl-0">
          <div class="accent--text text-h6 font-weight-bold mb-2">成效分析</div>
          <v-card width="100%" height="325" color="rgba(255, 255, 255, 0.7)">
            <Echart :data="chartOption" />
          </v-card>
        </v-col>
        <v-col cols="4" md="2" class="pr-0">
          <div class="accent--text text-h6 font-weight-bold mb-2">執行率</div>
          <v-card
            width="100%"
            height="332"
            class="d-flex align-center justify-center"
            color="rgba(255, 255, 255, 0.7)"
          >
            <div class="text-center">
              <v-progress-circular
                :rotate="-120"
                :size="100"
                :width="15"
                :value="93"
                color="#0067f6"
                class="mb-6"
              >
                <span class="text-h5 accent--text font-weight-bold"
                  >{{ 93 }}%</span
                >
              </v-progress-circular>
              <div class="accent--text">實際抑低量: 75 kW</div>
              <div class="accent--text">約定抑低量: 80 kW</div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-navigation-drawer>
</template>

<script>
import Echart from '@/components/Echart'

export default {
  components: { Echart },
  props: {
    isOpen: {
      required: true,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chartOption: {
        grid: {
          top: '15%',
          bottom: 60,
          left: 30,
          right: 80,
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          name: '時 : 分',
          nameTextStyle: {
            lineHeight: 20,
            verticalAlign: 'top',
            padding: [3, 0, 0, 8]
          },
          axisTick: { show: false },
          axisLine: {
            lineStyle: {
              color: '#889bb7'
            }
          }
        },
        yAxis: {
          type: 'value',
          name: 'kW',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#889bb7'
            }
          }
        },
        series: [
          {
            type: 'line',
            color: '#0067f6',
            data: [
              ['2019-10-10', 200],
              ['2019-10-11', 560],
              ['2019-10-12', 750],
              ['2019-10-13', 580],
              ['2019-10-14', 250],
              ['2019-10-15', 300],
              ['2019-10-16', 450],
              ['2019-10-17', 300],
              ['2019-10-18', 100]
            ],
            silent: true,
            symbol: 'circle',
            // endLabel: {
            //   show: true,
            //   formatter: (params) => {
            //     return `AVG: ${params}`
            //   }
            // },
            markLine: {
              silent: true,
              symbol: 'none',
              data: [
                {
                  yAxis: 700,
                  name: 'CBL',
                  itemStyle: { color: '#00d8c4' },
                  label: {
                    normal: {
                      show: 'end',
                      formatter: (params) => {
                        return params.name + ': ' + params.value
                      }
                    }
                  },
                  lineStyle: {
                    normal: {
                      type: 'solid'
                    }
                  }
                },
                {
                  type: 'average',
                  name: 'AVG',
                  itemStyle: { color: '#0067f6' },
                  label: {
                    normal: {
                      show: 'end',
                      formatter: (params) => {
                        return params.name + ': ' + params.value
                      }
                    }
                  },
                  lineStyle: {
                    normal: {
                      type: 'solid'
                    }
                  }
                }
              ]
            }
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.drawerStyle {
  background: transparent;
  backdrop-filter: blur(20px);
}
</style>
