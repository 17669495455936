<template>
  <div class="adr-history-container">
    <v-row justify="space-between">
      <v-col cols="12" sm="8" md="6" class="d-flex">
        <v-subheader class="ems-bluegrey--text font-weight-bold">
          選擇查詢的時間區間
        </v-subheader>
        <v-menu
          ref="menu"
          v-model="date.isMenuOpen"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              solo
              flat
              v-model="date.dates"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              required
              :rules="[
                (v) => !!v || '選擇時間區間',
                (v) => v.indexOf('~') > -1 || '請選擇時間結束日期'
              ]"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date.range"
            no-title
            range
            scrollable
            color="primary"
            :max="new Date().toISOString().substr(0, 10)"
            :min="
              moment().subtract(3, 'years').add(1, 'days').format('YYYY-MM-DD')
            "
            @input="pickDate"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="auto">
        <v-btn color="primary" text large :to="`/school/${tagId}/ADR/settings`">
          前往 ADR 設定 →
        </v-btn>
      </v-col>
    </v-row>
    <div v-if="searched">
      <v-sheet
        v-if="loadingSearch"
        width="100%"
        color="transparent"
        class="text-center"
      >
        <v-progress-circular
          class="mt-15"
          indeterminate
          size="50"
          color="grey"
        ></v-progress-circular>
        <div class="grey--text mt-3 font-weight-bold">載入中</div>
      </v-sheet>

      <v-list v-else class="transparent text-center font-weight-bold">
        <v-list-item class="item-header px-0" dense>
          <v-list-item-content v-for="h in headers" :key="h.value">
            <v-list-item-subtitle class="ems-bluegrey--text font-weight-bold">
              {{ h.text }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <!-- <v-btn
            x-large
            class="ml-3 transparent--text"
            depressed
            color="transparent"
            style="cursor: auto"
          >
            查看詳情
          </v-btn> -->
        </v-list-item>
        <template v-if="result.length">
          <v-list-item
            v-for="(data, key) in result"
            class="item-body px-0"
            :key="key"
          >
            <v-list-item-content
              v-for="header in headers"
              :key="header.value"
              :class="{
                'first-content': header.value === 'executeDate',
                'last-content': header.value === 'executionRate'
              }"
            >
              <v-list-item-subtitle class="accent--text">
                <span v-if="header.value === 'exeTime'">
                  {{ exeTimeFormat(data.startTime, data.endTime) }}
                </span>
                <span
                  v-if="
                    header.value === 'signalPayload' ||
                    header.value === 'realPayload' ||
                    header.value === 'executionRate'
                  "
                >
                  {{ data[header.value] || '- -' }}
                </span>
                <span v-else>
                  {{ data[header.value] }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <!-- <v-btn
              class="accent--text append-content ml-3 font-weight-bold"
              color="ems-aquamarine"
              x-large
              @click="isDrawerOpen = true"
              >查看詳情</v-btn
            > -->
          </v-list-item>
          <v-pagination
            v-model="page"
            :length="totalPage"
            color="accent"
            @input="pickDate"
          ></v-pagination>
        </template>
        <div v-else class="grey--text">( 無資料 )</div>
      </v-list>
      <HistoryDetail :isOpen.sync="isDrawerOpen" />
    </div>
  </div>
</template>

<script>
import OpenADR from '@/api/ems/OpenADR'
import HistoryDetail from './HistoryDetail'

export default {
  components: {
    HistoryDetail
  },
  props: {
    tagId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      date: {
        isMenuOpen: false,
        dates: '',
        range: []
      },
      headers: [
        { text: '執行日期', value: 'executeDate', align: 'center' },
        { text: '執行時段', value: 'exeTime', align: 'center' },
        { text: 'ADR方案', value: 'plan', align: 'center' },
        // { text: '實際卸載量(kW)', value: 'realPayload', align: 'center' },
        {
          text: '約定卸載量(kW)/設定溫度',
          value: 'signalPayload',
          align: 'center'
        }
        // { text: '執行率', value: 'executionRate', align: 'center' }
      ],
      result: [],
      page: 1,
      totalPage: 1,
      searched: false,
      loadingSearch: false,
      isDrawerOpen: false
    }
  },
  watch: {
    'date.range': function (val) {
      if (val.length === 0) this.date.dates = ''
      const dates = val
        .slice()
        .sort(
          (a, b) =>
            parseFloat(moment(a).format('x'), 10) -
            parseFloat(moment(b).format('x'), 10)
        )
      this.date.dates = dates.join(' ~ ')
    }
  },
  methods: {
    async pickDate() {
      if (this.date.range[0] && this.date.range[1]) {
        this.date.isMenuOpen = false
        this.searched = true
        this.loadingSearch = true
        try {
          const dates = this.date.range
            .slice()
            .sort(
              (a, b) =>
                parseFloat(moment(a).format('x'), 10) -
                parseFloat(moment(b).format('x'), 10)
            )
          const { data } = await OpenADR.getRecord(this.tagId, {
            startDate: dates[0],
            endDate: dates[1],
            direction: 'ASC',
            index: this.page,
            paginateCount: 10
          })
          this.result = data.data
          this.totalPage = data.allIndex || 1
        } catch (error) {
          console.error(error.response)
        } finally {
          this.loadingSearch = false
        }
      }
    },
    exeTimeFormat(startTime, endTime) {
      if (startTime) return `${startTime} ~ ${endTime || ''}`
      else return `Invalid date`
    }
  }
}
</script>

<style lang="scss" scoped>
.adr-history-container {
  padding: 20px;
}
.v-list::v-deep {
  .item-body {
    height: 60px;
    margin-bottom: 13px;
    .v-list-item__content {
      height: 100%;
      background-color: rgba(255, 255, 255, 0.6);
      &.first-content {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &.last-content {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
    .append-content {
      height: 100%;
    }
  }
}
</style>
