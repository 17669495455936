var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"adr-history-container"},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"8","md":"6"}},[_c('v-subheader',{staticClass:"ems-bluegrey--text font-weight-bold"},[_vm._v(" 選擇查詢的時間區間 ")]),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"solo":"","flat":"","prepend-inner-icon":"mdi-calendar","readonly":"","required":"","rules":[
              function (v) { return !!v || '選擇時間區間'; },
              function (v) { return v.indexOf('~') > -1 || '請選擇時間結束日期'; }
            ]},model:{value:(_vm.date.dates),callback:function ($$v) {_vm.$set(_vm.date, "dates", $$v)},expression:"date.dates"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.date.isMenuOpen),callback:function ($$v) {_vm.$set(_vm.date, "isMenuOpen", $$v)},expression:"date.isMenuOpen"}},[_c('v-date-picker',{attrs:{"no-title":"","range":"","scrollable":"","color":"primary","max":new Date().toISOString().substr(0, 10),"min":_vm.moment().subtract(3, 'years').add(1, 'days').format('YYYY-MM-DD')},on:{"input":_vm.pickDate},model:{value:(_vm.date.range),callback:function ($$v) {_vm.$set(_vm.date, "range", $$v)},expression:"date.range"}})],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","text":"","large":"","to":("/school/" + _vm.tagId + "/ADR/settings")}},[_vm._v(" 前往 ADR 設定 → ")])],1)],1),(_vm.searched)?_c('div',[(_vm.loadingSearch)?_c('v-sheet',{staticClass:"text-center",attrs:{"width":"100%","color":"transparent"}},[_c('v-progress-circular',{staticClass:"mt-15",attrs:{"indeterminate":"","size":"50","color":"grey"}}),_c('div',{staticClass:"grey--text mt-3 font-weight-bold"},[_vm._v("載入中")])],1):_c('v-list',{staticClass:"transparent text-center font-weight-bold"},[_c('v-list-item',{staticClass:"item-header px-0",attrs:{"dense":""}},_vm._l((_vm.headers),function(h){return _c('v-list-item-content',{key:h.value},[_c('v-list-item-subtitle',{staticClass:"ems-bluegrey--text font-weight-bold"},[_vm._v(" "+_vm._s(h.text)+" ")])],1)}),1),(_vm.result.length)?[_vm._l((_vm.result),function(data,key){return _c('v-list-item',{key:key,staticClass:"item-body px-0"},_vm._l((_vm.headers),function(header){return _c('v-list-item-content',{key:header.value,class:{
              'first-content': header.value === 'executeDate',
              'last-content': header.value === 'executionRate'
            }},[_c('v-list-item-subtitle',{staticClass:"accent--text"},[(header.value === 'exeTime')?_c('span',[_vm._v(" "+_vm._s(_vm.exeTimeFormat(data.startTime, data.endTime))+" ")]):_vm._e(),(
                  header.value === 'signalPayload' ||
                  header.value === 'realPayload' ||
                  header.value === 'executionRate'
                )?_c('span',[_vm._v(" "+_vm._s(data[header.value] || '- -')+" ")]):_c('span',[_vm._v(" "+_vm._s(data[header.value])+" ")])])],1)}),1)}),_c('v-pagination',{attrs:{"length":_vm.totalPage,"color":"accent"},on:{"input":_vm.pickDate},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]:_c('div',{staticClass:"grey--text"},[_vm._v("( 無資料 )")])],2),_c('HistoryDetail',{attrs:{"isOpen":_vm.isDrawerOpen},on:{"update:isOpen":function($event){_vm.isDrawerOpen=$event},"update:is-open":function($event){_vm.isDrawerOpen=$event}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }